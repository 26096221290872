import { Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import {
    FaFlask,
    FaMedapps,
    FaUsers,
    FaBezierCurve,
    FaBlog,
    FaPodcast,
    FaWrench,
    FaProjectDiagram,
    FaBook,
    FaFastForward,
} from "react-icons/fa";

import Logo from "../images/gw-logo-white-transparent.png";

export default class Header extends React.Component {
    componentDidMount() {
        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll(".navbar-burger"), 0);
        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {
            // Add a click event on each of them
            $navbarBurgers.forEach((el) => {
                el.addEventListener("click", () => {
                    // Get the target from the "data-target" attribute
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);
                    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                    el.classList.toggle("is-active");
                    $target.classList.toggle("is-active");
                });
            });
        }
    }

    render() {
        return (
            <>
                <Helmet bodyAttributes={{ class: "has-navbar-fixed-top" }} />
                {/* use Helmet to add appropriate class to body element for fixed top navbarl */}
                <header>
                    <nav className="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation">
                        <div className="container">
                            <div className="navbar-brand">
                                <Link to="/" className="navbar-item">
                                    <img id="titlebar-logo" alt="GalaxyWorks Logo" src={Logo} />
                                </Link>
                                <a
                                    role="button"
                                    className="navbar-burger"
                                    data-target="navMenu"
                                    aria-label="menu"
                                    aria-expanded="false"
                                >
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                    <span aria-hidden="true"></span>
                                </a>
                            </div>
                            <div className="navbar-menu" id="navMenu">
                                <div className="navbar-end">
                                    <Link activeStyle={{ fontWeight: "bold" }} to="/" className="navbar-item">
                                        Home
                                    </Link>
                                    <div className="navbar-item has-dropdown is-hoverable">
                                        <a className="navbar-link">Use Cases</a>
                                        <div className="navbar-dropdown">
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/analyze-your-own-data"
                                                className="navbar-item"
                                            >
                                                <FaMedapps className="mr-3" />
                                                Analyze Your Own Data
                                            </Link>
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/custom-tools"
                                                className="navbar-item"
                                            >
                                                <FaWrench className="mr-3" />
                                                Use Custom Tools
                                            </Link>
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/efficient-lab-environment"
                                                className="navbar-item"
                                            >
                                                <FaFlask className="mr-3" />
                                                Create an Efficient Lab Environment
                                            </Link>
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/automate-repetitive-analyses"
                                                className="navbar-item"
                                            >
                                                <FaBezierCurve className="mr-3" />
                                                Automate Repetitive Analyses
                                            </Link>
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/training-and-collaboration"
                                                className="navbar-item"
                                            >
                                                <FaUsers className="mr-3" />
                                                Training and Collaboration
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="navbar-item has-dropdown is-hoverable">
                                        <a className="navbar-link">Resources</a>
                                        <div className="navbar-dropdown">
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/webinars"
                                                className="navbar-item"
                                            >
                                                <FaPodcast className="mr-3" />
                                                Webinars
                                            </Link>
                                            <Link
                                                activeStyle={{ fontWeight: "bold" }}
                                                to="/blog"
                                                className="navbar-item"
                                            >
                                                <FaBlog className="mr-3" />
                                                Blog
                                            </Link>

                                            <div className="nested navbar-item dropdown">
                                                <div className="dropdown-trigger">
                                                    <button
                                                        className="button"
                                                        aria-haspopup="true"
                                                        aria-controls="dropdown-menu"
                                                    >
                                                        <FaProjectDiagram className="mr-3" />
                                                        <span>Pro Workflows</span>
                                                    </button>
                                                </div>
                                                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                                                    <div className="dropdown-content">
                                                        <span className="navbar-item is-uppercase has-text-weight-bold">
                                                            <FaBook className="mr-3" />
                                                            Manuals
                                                        </span>
                                                        <Link
                                                            activeStyle={{ fontWeight: "bold" }}
                                                            to="/docs/two-sample-rna-seq"
                                                            className="navbar-item"
                                                        >
                                                            Two Sample RNA-seq
                                                        </Link>
                                                        <hr className="navbar-divider"></hr>
                                                        <span className="navbar-item is-uppercase has-text-weight-bold">
                                                            <FaFastForward className="mr-3" />
                                                            Quickstart Guides
                                                        </span>
                                                        <Link
                                                            activeStyle={{ fontWeight: "bold" }}
                                                            to="/docs/two-sample-rna-seq-quickstart"
                                                            className="navbar-item"
                                                        >
                                                            Two Sample RNA-seq
                                                        </Link>
                                                        <Link
                                                            activeStyle={{ fontWeight: "bold" }}
                                                            to="/docs/illumina-variant-calling"
                                                            className="navbar-item"
                                                        >
                                                            Illumina Variant Calling
                                                        </Link>
                                                        <Link
                                                            activeStyle={{ fontWeight: "bold" }}
                                                            to="/docs/ont-variant-calling"
                                                            className="navbar-item"
                                                        >
                                                            ONT Variant Calling
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Link activeStyle={{ fontWeight: "bold" }} to="/pricing" className="navbar-item">
                                        Pricing
                                    </Link>
                                    <Link activeStyle={{ fontWeight: "bold" }} to="/careers" className="navbar-item">
                                        Join Us
                                    </Link>
                                    <Link activeStyle={{ fontWeight: "bold" }} to="/about" className="navbar-item">
                                        About
                                    </Link>
                                    <Link activeStyle={{ fontWeight: "bold" }} to="/contact" className="navbar-item">
                                        Contact
                                    </Link>
                                    {/*}
                                    <div className="navbar-item">
                                        <Link
                                            activeStyle={{ fontWeight: "bold" }}
                                            to="https://portal.galaxyworks.io/"
                                            className="button is-warning"
                                        >
                                            User Portal
                                        </Link>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </>
        );
    }
}
