import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { FaLinkedin, FaTwitter, FaEnvelope } from "react-icons/fa";

const FooterList = [
    {
        title: "Home",
        list: [
            {
                title: "Pricing",
                link: "/pricing",
            },
            {
                title: "Careers",
                link: "/careers",
            },
            {
                title: "About",
                link: "/about",
            },
            {
                title: "Legal",
                link: "/legal/terms-of-service",
            },
        ],
    },
    {
        title: "Learn More",
        list: [
            {
                title: "Blog",
                link: "/blog",
            },
            {
                title: "Webinars",
                link: "/webinars",
            },
        ],
    },
    {
        title: "Reach Out",
        list: [
            {
                title: "Twitter",
                link: "https://twitter.com/GxyWorks",
                icon: <FaTwitter />,
            },
            {
                title: "LinkedIn",
                link: "https://www.linkedin.com/company/galaxyworks",
                icon: <FaLinkedin />,
            },
            {
                title: "Contact Us",
                link: "/contact",
                icon: <FaEnvelope />,
            },
        ],
    },
];

const FooterLinks = (props) => (
    <div>
        {props.list.map((node, key) => (
            <p className="mb-2  has-text-left" key={key}>
                <Link className="is-size-6 has-text-light" to={node.link}>
                    <span className="mr-2 feature-list-icon">{node.icon}</span>
                    {node.title}
                </Link>
            </p>
        ))}
    </div>
);

FooterLinks.propTypes = {
    list: PropTypes.array,
};

const Footer = () => (
    <footer className="footer has-text-centered">
        <div className="container">
            <div className="columns">
                <div className="column">
                    <p className="has-text-left">
                        <small>691 McDaniel St SW</small>
                    </p>
                    <p className="has-text-left">
                        <small>Atlanta, GA 30310</small>
                    </p>
                    <p className="has-text-left">
                        <small>United States</small>
                    </p>
                    <p className="has-text-left mt-5">
                        <small>© {new Date().getFullYear()}, GalaxyWorks LLC</small>
                    </p>
                </div>
                <div className="column">
                    <div className="columns">
                        {FooterList.map((node, key) => (
                            <div className="column" key={key}>
                                <div className="mb-5 has-text-left">
                                    <div className="title is-size-6 has-text-light">{node.title}</div>
                                </div>
                                <FooterLinks list={node.list} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
